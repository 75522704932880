import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "delete-a-channel"
    }}>{`Delete a Channel`}</h1>
    <hr></hr>
    <p>{`To provide delete a Channel, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Channels section from the left side menu options. `}</li>
      <li parentName="ol">{`Select 'Delete' next to the Channel you wish you delete. `}</li>
      <li parentName="ol">{`Confirm the deletion. Deleting the Channel will permanently remove it from any tabs. `}</li>
    </ol>
    <p><img alt="CMS Delete Channel" src={require("./images/delete_channel.png")} />{` `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      